.over_map {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
  width: 100%;
}

.wrapper {
  position: relative;
  height: auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.row_title {
  border: 1px;
  border-radius: 0px 0px 50px 0px;
  width: 1000px;
  height: 240px;
  background: rgba(0, 0.6, 0, 0.6);
  position: relative;
}

.text_title {
  font-size: 40px;
  color: white;
  font-family: Russo One;
  font-weight: bold;
  padding-top: 115px;
}

.text_time {
  font-size: 30px;
  font-weight: bold;
  color: white;
  font-family: Russo One;
}

.custom-popup {
  position: absolute;
  z-index: 100;
}

.custom-popup button {
  background-color: #ffffff;
  border-color: #000000;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.custom-popup button:hover {
  background-color: #000000;
  color: rgb(255, 255, 255);
}

.custom-popup .text_popup {
  font-size: 40px;
  color: black;
  font-weight: bolder;
  height: 150px;
  text-align: center;
  background-color: rgba(202, 202, 202, 0.795);
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.custom-popup .text_popup2 {
  font-size: 17px;
  color: black;
  font-family: Russo One;
  background-color: rgba(202, 202, 202, 0.795);
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 38px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.custom-popup .transparent_box {
  height: 10px;
  background-color: transparent;
}
.custom-popup .line {
  display: flex;
}
.custom-popup .line .design_box {
  // height: 15px;
  // background-color: black;
  // border-radius: 5px;
  width: 25px;
  height: 25px;
  transform: skewX(-20deg);
  background-color: black;
  margin: 10px;
}